<template>




            <h6 style="text-align: center">
              <b-badge> Next Step:  <br class="mobile"> {{ nextStepText }}</b-badge>
            </h6>

</template>

<script>
import { BAlert, BRow, BCol, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
export default {
  components: { BAlert, BRow, BCol, BButton },
  name: "MainAlert",
  props: [
    "nextStepText",
  ],
  data() {
    return {
      score: [],
    };
  },
  created() {

  },
  methods: {
    checkStatus(data){
      return 'success'
    },
    goToFunction() {
      this.$http
          .post(`/nextStep/changeStatus/${this.nextStep.id}`)
          .then((res) => {});
    },
  },
};
</script>
